*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

/*# sourceMappingURL=index.8eee13f5.css.map */
