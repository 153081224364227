*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%
